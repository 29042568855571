import React, { useState } from 'react';
import styled from 'styled-components/macro';

import PagePanel from '@components/pages/PagePanel';
import Alert from '@components/elements/Alert';
import { CompoundEditor } from '@components/compoundEditor/CompoundEditor';
import useCompounds from '@components/compounds/useCompounds';
import TextButton from '@components/controls/TextButton';
import BackArrowIcon from '@components/icons/backArrow.icon';
import ForwardArrowIcon from '@components/icons/forwardArrow.icon';
import { CompoundMetaT, Structure } from '@pages/PgCompoundsAdd';
import { CompoundT } from '@src/type';

interface EditStructurePropsT {
  structure: Structure;
  filename: string;
  setStructure: React.Dispatch<React.SetStateAction<Structure>>;
  setCompound: React.Dispatch<React.SetStateAction<CompoundT>>;
  setCompoundMeta: React.Dispatch<React.SetStateAction<CompoundMetaT>>;
  priorStep: () => void;
  nextStep: () => void;
}

const debug = false;
const EditStructure = (props: EditStructurePropsT) => {
  const {
    structure,
    filename,
    setStructure,
    setCompound,
    setCompoundMeta,
    priorStep,
    nextStep,
  } = props;
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  let errorMessage =
    'There appears to be an issue with the structure. ' +
    'Computing properties failed. ' +
    'Review structure and try again.';

  // API or CONTEXTS
  const { calculateProperties } = useCompounds();

  const handleCompoundEdit = (mol, smiles) => {
    if (debug) console.log('EditStructure | handleCompoundEdit ', mol, smiles);
    setStructure({ mol, smiles });
  };

  const getProperties = () => {
    if (debug) console.log('EditStructure | getProperties ', structure);
    calculateProperties({ mol: structure.mol })
      .then(({ data }) => {
        if (debug) console.log('getProperties | data: ', data);

        setCompoundMeta({
          filename: filename,
          svg: data?.svg,
          mol_svg: '',
          structure: data?.structure,
          neighbors: [],
        });

        setCompound(data.record);
        nextStep();
      })
      .catch((err) => {
        console.log('Error from getProperties: ERROR', err);
        setAlertOpen(true);
      });
  };

  let buttonWidth = 220;
  let buttonHeight = 40;
  let buttonMargin = '4px 10px';

  return (
    <EditStructureContainer width={880}>
      <PagePanel width={'880px'} height={'570px'}>
        <Editor>
          <EditorSaveBanner>
            <TextButton
              text={'Re-enter Structure'}
              onClick={priorStep}
              icon={<BackArrowIcon size={32} />}
              width={buttonWidth}
              height={buttonHeight}
              margin={buttonMargin}
            />
            <TextButton
              text={'Review Properties'}
              onClick={getProperties}
              icon={<ForwardArrowIcon size={32} />}
              width={buttonWidth}
              height={buttonHeight}
              margin={buttonMargin}
              iconRight={true}
            />
          </EditorSaveBanner>
          <CompoundEditor mol={structure.mol} onChange={handleCompoundEdit} />
        </Editor>
        <Alert
          type={'general'}
          title={'Warning: Structure Issue'}
          message={errorMessage}
          alertOpen={alertOpen}
          closeAlert={() => setAlertOpen(false)}
        />
      </PagePanel>
    </EditStructureContainer>
  );
};

export default EditStructure;

const EditStructureContainer = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  width: ${(p) => p.width}px;
`;

const EditorSaveBanner = styled.div`
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Editor = styled.div`
  position: relative;
  width: 850px;
  height: 500px;
  margin: 0 auto;
`;
