import React from 'react';
import _ from 'lodash';

import { ValueRendererT } from '@stores/fields';
import GenericRenderer from './valueRenderers/GenericRenderer';
import MultiSelectRenderer from './valueRenderers/MultiSelectRenderer';
import ObjectlistRenderer from './valueRenderers/ObjectlistRenderer';
import SingleSelectRenderer from './valueRenderers/SingleSelectRenderer';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  getFieldOptions,
  getCompoundFieldValue,
} from '@components/elements/compound/utils';
import { CompoundT } from '@src/type';

interface DisplayEditableValueProps {
  compound: CompoundT;
  fieldId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allFields: any;
  handleChange: (fieldId: string, value: string | number) => void;
  inputErrors: Array<{
    id: string;
    text: string;
  }>;
}

const debug = false;
const DisplayEditableValue = (props: DisplayEditableValueProps) => {
  const { compound, fieldId, allFields, handleChange, inputErrors } = props;

  const getRenderer = (fieldId, allFields) => {
    return _.get(allFields, `${fieldId}.value_renderer`, ValueRendererT.string);
  };

  const getValue = () => {
    if (debug) console.log('DisplayEditableValue | getValue() called');
    return getCompoundFieldValue(compound, fieldId, allFields);
  };

  const _handleChange = (event: SelectChangeEvent<unknown>) => {
    debug &&
      console.log(
        'DisplayEditableValue | event.target.value:',
        event.target.value
      );
  };

  return (
    <>
      {getRenderer(fieldId, allFields) === ValueRendererT.objectlist ? (
        <ObjectlistRenderer
          compound={compound}
          fields={allFields}
          fieldId={fieldId}
          handleChange={(payload) =>
            handleChange(payload.fieldId, payload.value)
          }
        />
      ) : getRenderer(fieldId, allFields) === ValueRendererT.singleselect ? (
        <SingleSelectRenderer
          getValue={getValue}
          getOptions={getFieldOptions(fieldId, allFields)}
          onChange={_handleChange}
        />
      ) : getRenderer(fieldId, allFields) === ValueRendererT.multiselect ? (
        <MultiSelectRenderer
          compound={compound}
          fields={allFields}
          fieldId={fieldId}
          handleChange={(payload) =>
            handleChange(payload.fieldId, payload.value)
          }
        />
      ) : (
        <GenericRenderer
          fieldId={fieldId}
          handleChange={(value) => handleChange(fieldId, value)}
          error={inputErrors.find((error) => error.id === fieldId)}
        />
      )}
    </>
  );
};

export default DisplayEditableValue;
