import _ from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { ltrim } from '@utils/misc';
import { CompoundT } from '@src/type';

interface MultiSelectRendererProps {
  compound: CompoundT;
  fields: Array<Record<string, string>>;
  fieldId: string;
  propId?: string;
  handleChange: (payload) => void;
}

const MultiSelectRenderer = (props: MultiSelectRendererProps) => {
  const { compound, fields, fieldId, propId, handleChange } = props;

  const _handleChange = (event) => {
    const value = ltrim(_.get(event, 'target.value', []).join(';'), ';');
    handleChange({
      action: 'UPDATECOMPOUND',
      payload: { propId, newValue: value },
    });
  };

  const getValue = React.useCallback(
    (propId: string): string[] => {
      const value = _.get(compound, propId, '').split(';');
      if (value.length === 1 && value[0] === '') {
        return [];
      } else {
        return value;
      }
    },
    [compound]
  );

  const getOptions = React.useCallback(
    (fieldId: string): string[] => {
      return _.get(fields, `${fieldId}.field_options`, '').split(';');
    },
    [fields]
  );

  return (
    <Container>
      <StyledFormControl>
        <StyledSelect
          variant={'standard'}
          value={getValue(propId)}
          onChange={_handleChange}
          multiple
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(selected) => (
            <Chips>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Chips>
          )}
        >
          {getOptions(fieldId).map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </Container>
  );
};

export default MultiSelectRenderer;

const Container = styled.div`
  border-radius: ${(p) => p.theme.borderRadius};
  border: ${(p) => p.theme.borders.thin};
`;

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;

  & .MuiChip-root {
    color: ${(props) => props.theme.palette.textPrimary};
    font-family: ${(props) => props.theme.fonts.main};
    font-weight: ${(props) => props.theme.weights.light};
    font-size: ${(props) => props.theme.sizes.small};
    margin-left: ${(props) => props.theme.sizes.xxsmall};
    margin-top: ${(props) => props.theme.sizes.xxsmall};
    background-color: ${(props) => props.theme.palette.accentPrimary};
  }
`;

const StyledFormControl = styled(FormControl)`
  color: ${(props) => props.theme.palette.textPrimary};
  margin: ${(props) => props.theme.sizes.xsmall};
  min-width: ${(props) => props.theme.sizes.xsmall};
  border: ${(p) => p.theme.borders.thin};

  & .MuiSelect-icon {
    color: ${(props) => props.theme.palette.accentPrimary};
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  & .MuiSelect-select-outlined {
    border: ${(p) => p.theme.borders.thin};
    padding: 0;
  }
`;
